
import { Options, Vue } from "vue-class-component";
import {
  get_all_field_list,
  create_field,
  edit_field,
  get_all_article_list,
  edit_article,
} from "@/api/article.api";
import { mapActions, mapState } from "vuex";
import { ElMessage } from "element-plus";
@Options({
  components: {},
  async created() {
    // 获取分区列表
    this.field_name_list = await get_all_field_list();
    // 设置默认选中分区为第一
    if (this.field_name_list.length > 0) {
      await this.selectField(0);
    }
    document.title = "文章管理 | leoyi编程日志";
  },
  data() {
    return {
      field_name_list: [],
      actived_field: {},
      edit_field: {},
      active_index: null,
      create_dialog_visible: false,
      edit_dialog_visible: false,
      new_field: {
        field: "",
        order: 0,
        isVisiable: false,
      },
      article_list: [],
      // 文章列表分页设置
      article_total: 0, // 文章总量
      article_page: 1, // 当前页面
      article_page_size: 5, // 页面大小
    };
  },
  computed: {
    ...mapState("user", ["userId"]),
  },
  watch: {
    async article_page(newPage, curPage) {
      // 请求文章列表
      const res = await get_all_article_list({
        userId: this.userId,
        fieldId: this.actived_field.id,
        offset: (this.article_page - 1) * this.article_page_size,
        num: this.article_page_size,
      });
      this.article_list = res.list;
      this.article_total = res.total;
    },
  },
  methods: {
    // ...mapActions("user", ["requestUserInfo"]),
    sort_field() {
      // 按order升序
      this.field_name_list.sort((a: any, b: any) => a.order - b.order);
      // 更改当前选中的tag的index以移动侧标
      this.active_index = this.field_name_list.findIndex((field: any) => {
        return field.id == this.actived_field.id;
      });
    },
    async commit_new_field() {
      if (!this.new_field.field || this.new_field.field == "") {
        ElMessage.error("分区名不能为空");
        return;
      }
      const id = (await create_field(this.new_field)) + "";
      // 选中新添加的分区
      this.field_name_list = await get_all_field_list();
      let field_index = this.field_name_list.findIndex((field: any) => {
        return field.id == id;
      });
      this.selectField(field_index);
      this.new_field = {};
      this.create_dialog_visible = false;
      ElMessage.success("创建分区成功");
    },

    async commit_edit_field() {
      if (!this.edit_field.field || this.edit_field.field == "") {
        ElMessage.error("分区名不能为空");
        return;
      }
      await edit_field(this.edit_field);
      ElMessage.success("修改分区成功");
      this.edit_field = {};
      this.edit_dialog_visible = false;
      this.sort_field();
    },
    async commit_delete_field() {
      await edit_field({
        ...this.edit_field,
        isDeleted: true,
      });
      this.field_name_list = await get_all_field_list();
      if (this.actived_field.id == this.edit_field.id) {
        // 如果删除的是当前选中的分区,则选中第一个分区
        this.active_index = 0;
        this.actived_field = this.field_name_list[0];
      }
      ElMessage.success("删除分区成功");
      this.edit_field = {};
      this.edit_dialog_visible = false;
    },
    async commit_delete_article(articleId: number) {
      const res = await edit_article({
        id: articleId,
        isDeleted: true,
      });
      if (res) {
        ElMessage.success("删除文章成功");
        // 更新分区列表
        this.field_name_list = await get_all_field_list();
        // 更新文章列表
        const res = await get_all_article_list({
          userId: this.userId,
          fieldId: this.actived_field.id,
          offset: 0,
          num: this.article_page_size,
        });
        this.article_list = res.list;
        this.article_total = res.total;
      }
    },
    async selectField(index: number) {
      if (this.active_index == index) {
        return;
      }
      this.active_index = index;
      this.actived_field = this.field_name_list[index];
      this.article_page = 1;
      this.article_page_size = 5;
      // 请求文章列表
      const res = await get_all_article_list({
        userId: this.userId,
        fieldId: this.actived_field.id,
        offset: 0,
        num: this.article_page_size,
      });
      this.article_list = res.list;
      this.article_total = res.total;
    },
    // 更新文章可见状态
    async update_article_visiable(article: any) {
      // console.log("触发change");
      const res = await edit_article({
        id: article.id,
        isVisiable: article.isVisiable,
      });
      if (res) {
        ElMessage.success("修改已保存");
      }
    },

    // 打开页面
    gotoDetail(articleId: number) {
      // // console.log(this.$router)
      window.open(window.location.origin + `/article/${articleId}`, "_blank");
    },
    gotoEdit(articleId: number) {
      // // console.log(this.$router)
      window.open(
        window.location.origin + `/article/edit/${articleId}`,
        "_blank"
      );
    },
  },
})
export default class Home extends Vue {}
